<template>
  <div>
    <app-loading v-if="loading" />
    <new-accounting-account v-if="showNewAccountModal" />
    <edit-accounting-account v-if="showEditAccountModal" />
    <new-move
      :income="false"
      v-if="showNewMoveModal"
      :account-parent="parent_id"
    />
    <v-container v-if="!loading">
      <v-row>
        <v-col
          class="py-0"
          cols="12"
        >
          <app-data-table
            :title=" $vuetify.lang.t('$vuetify.titles.list', [
                $vuetify.lang.t('$vuetify.menu.accountings')])"
            csv-filename="Categories"
            :headers="getTableColumns"
            :options="vBindOption"
            :items="accounts"
            :manager="'accounting_account'"
            :sort-by="['firstName']"
            :sort-desc="[false, true]"
            multi-sort
            @create-row="toogleNewAccountsModal(true)"
            @edit-row="editMoveHandler($event)"
            @delete-row="deleteMoveHandler($event)"
          >
            <template v-slot:[`item.name`]="{ item }">
              <template>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="addAccountingMove(item)"
                    >
                      mdi-tag-plus
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.created', [$vuetify.lang.t('$vuetify.menu.account_move')]) }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="listMoveByCategory(item.id)"
                    >
                      mdi-tag-multiple
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.show_p', [$vuetify.lang.t('$vuetify.menu.account_moves')]) }}</span>
                </v-tooltip>
              </template>
              {{ item.default ? getNameCategory(item) : item.name }}
            </template>
            <template v-slot:[`item.init_balance`]="{ item }">
              {{ parseFloat(item.init_balance).toFixed(2) + ' ' + getCurrency }}
            </template>
            <template v-slot:[`item.current_balance`]="{ item }">
              {{ parseFloat(item.current_balance).toFixed(2) + ' ' + getCurrency }}
            </template>
            <template v-slot:[`item.category.nature`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $vuetify.lang.t('$vuetify.accounting_category.' + item.category.nature) }}</span>
                </template>
                <span style="max-width: 200px">
                  <h4>{{ $vuetify.lang.t('$vuetify.accounting_category.' + item.category.nature) }}</h4>
                  <p>{{ $vuetify.lang.t('$vuetify.accounting_category.description_nature.' + item.category.nature) }}</p>
                </span>
              </v-tooltip>
            </template>
              <template
                  v-slot:[`item.data-table-expand`]="{
              item,
              expand,
              isExpanded
            }"
              >
                  <v-btn
                      v-if="item.move.length > 0"
                      color="primary"
                      fab
                      x-small
                      dark
                      @click="expand(!isExpanded)"
                  >
                      <v-icon v-if="isExpanded">
                          mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                          mdi-chevron-down
                      </v-icon>
                  </v-btn>
                  <v-btn
                      v-else
                      fab
                      x-small
                      disabled
                  >
                      <v-icon>
                          mdi-check
                      </v-icon>
                  </v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                  <td
                      :colspan="headers.length"
                      style="padding: 0 0 0 0"
                  >
                    <move-account v-if="item.move.length > 0" :moves="item.move">
                    </move-account>
<!--                      <composite-list v-if="item.composite" :article="item" :show-header="false"/>-->
                  </td>
              </template>
          </app-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewAccountingAccount from './NewAccountingAccount'
import EditAccountingAccount from './EditAccountingAccount'
import NewMove from '../../accounting-move/commerce/NewMove'
import MoveAccount from './MoveAccount'
// import CompositeList from '../../article/commerce/manager-article/CompositeList'

export default {
  name: 'ListAccountingAccount',
  components: { MoveAccount, NewAccountingAccount, EditAccountingAccount, NewMove },
  data () {
    return {
      vBindOption: {
        singleExpand: false,
        showExpand: true
      },
      parent_id: null,
      loading: false
    }
  },
  computed: {
    ...mapState('account', [
      'showNewAccountModal',
      'showEditAccountModal',
      'accounts',
      'isTableLoading'
    ]),
    ...mapGetters('auth', ['user']),
    ...mapState('accountMove', [
      'showNewMoveModal'
    ]),
    ...mapState('auth', ['company_subscription']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.nature'),
          value: 'category.nature',
          select_filter: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.boxes.init'),
          value: 'init_balance',
          select_filter: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.boxes.current'),
          value: 'current_balance',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    },
    getMoveColummns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'date',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.assets'),
          value: 'credit',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.debit'),
          value: 'debit'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    this.loading = true
    await this.getAccounts()
    this.loading = false
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'toogleNewAccountsModal', 'openEditAccountsModal', 'deleteAccount']),
    ...mapActions('accountMove', [
      'toogleNewMovesModal'
    ]),
    getNameCategory (item) {
      const splitName = item.name.split('-')
      return splitName[0] + '-' + this.$vuetify.lang.t('$vuetify.accounting_category.' + splitName[1])
      // return item
    },
    addAccountingMove (item) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.parent_id = item
        this.toogleNewMovesModal(true)
      }
    },
    listMoveByCategory ($id) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.$router.push({ name: 'account_move_by_category', params: { account: $id } }) }
    },
    editMoveHandler ($id) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.openEditAccountsModal(this.accounts.filter(acc => acc.id === $id)[0]) }
    },
    deleteMoveHandler ($id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.accountings')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) {
            this.deleteAccount($id).then(() => {
              this.getAccounts()
            })
          }
        })
    },
    showSubscriptionMessage () {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
          text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'my_subscription' })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
