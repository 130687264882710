<template>
  <v-data-table
    :headers="getHeaders"
    :items="moves"
    :items-per-page="10"
    class="elevation-1"
  >

    <template v-slot:[`item.date`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{ $moment(item.created_at).format('(dddd) DD-MM-yyyy HH:mm') }}</span>
        </template>
        <span> {{ $vuetify.lang.t('$vuetify.date')  + ': '+ $moment(item.created_at).format('dddd d-MM-yyyy HH:mm')}}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.credit`]="{ item }">
      {{ parseFloat(item.credit).toFixed(2) + ' ' +getCurrency}}
    </template>
    <template v-slot:[`item.debit`]="{ item }">
      {{parseFloat(item.debit).toFixed(2) + ' ' +getCurrency}}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'MoveAccount',
  props: {
    moves: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getHeaders () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'date',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.assets'),
          value: 'credit',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.debit'),
          value: 'debit',
          select_filter: true
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
